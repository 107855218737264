/* lt */
@mixin lt-min {
    @media (min-width: $lt) {
        @content;
    }
}

@mixin lr {
    @media (max-width: $lr - 0.2) {
        @content;
    }
}

@mixin lv {
    @media (max-width: $lv - 0.2) {
        @content;
    }
}


@mixin lt {
    @media (max-width: $lt - 0.2) {
        @content;
    }
}

@mixin lt-ul {
    @media (max-width: $lt - 0.2) and (min-width: $ul) {
        @content;
    }
}

@mixin lt-xl {
    @media (max-width: $lt - 0.2) and (min-width: $xl) {
        @content;
    }
}

@mixin lt-lg {
    @media (max-width: $lt - 0.2) and (min-width: $lg) {
        @content;
    }
}

@mixin lt-md {
    @media (max-width: $lt - 0.2) and (min-width: $md) {
        @content;
    }
}

@mixin lt-sm {
    @media (max-width: $lt - 0.2) and (min-width: $sm) {
        @content;
    }
}

@mixin lt-xs {
    @media (max-width: $lt - 0.2) and (min-width: $xs) {
        @content;
    }
}


/* ul */
@mixin ul-min {
  @media (min-width: $ul) {
    @content;
  }
}

@mixin ul {
  @media (max-width: $ul - 0.2) {
    @content;
  }
}

@mixin ul-xl {
  @media (max-width: $ul - 0.2) and (min-width: $xl) {
    @content;
  }
}

@mixin ul-lg {
  @media (max-width: $ul - 0.2) and (min-width: $lg) {
    @content;
  }
}

@mixin ul-md {
  @media (max-width: $ul - 0.2) and (min-width: $md) {
    @content;
  }
}

@mixin ul-sm {
  @media (max-width: $ul - 0.2) and (min-width: $sm) {
    @content;
  }
}

@mixin ul-xs {
  @media (max-width: $ul - 0.2) and (min-width: $xs) {
    @content;
  }
}

/* xl */
@mixin xl-min {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin xl {
  @media (max-width: $xl - 0.2) {
    @content;
  }
}

@mixin xl-lg {
  @media (max-width: $xl - 0.2) and (min-width: $lg) {
    @content;
  }
}

@mixin xl-md {
  @media (max-width: $xl - 0.2) and (min-width: $md) {
    @content;
  }
}

@mixin xl-sm {
  @media (max-width: $xl - 0.2) and (min-width: $sm) {
    @content;
  }
}

@mixin xl-xs {
  @media (max-width: $xl - 0.2) and (min-width: $xs) {
    @content;
  }
}

/* lg */
@mixin lg-min {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin lg {
  @media (max-width: $lg - 0.2) {
    @content;
  }
}

@mixin lg-md {
  @media (max-width: $lg - 0.2) and (min-width: $md) {
    @content;
  }
}

@mixin lg-sm {
  @media (max-width: $lg - 0.2) and (min-width: $sm) {
    @content;
  }
}

@mixin lg-xs {
  @media (max-width: $lg - 0.2) and (min-width: $xs) {
    @content;
  }
}
@mixin lg-xx {
    @media (max-width: $lg - 0.2) and (min-width: $xx) {
        @content;
    }
}

/* md */
@mixin md-min {
  @media (min-width: $md) {
    @content;
  }
}

@mixin md {
  @media (max-width: $md - 0.2) {
    @content;
  }
}

@mixin md-sm {
  @media (max-width: $md - 0.2) and (min-width: $sm) {
    @content;
  }
}

@mixin md-xs {
  @media (max-width: $md - 0.2) and (min-width: $xs) {
    @content;
  }
}

@mixin md-xx {
  @media (max-width: $md - 0.2) and (min-width: $xx) {
    @content;
  }
}

/* sm */
@mixin sm-min {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $sm - 0.2) {
    @content;
  }
}

@mixin sm-xs {
  @media (max-width: $sm - 0.2) and (min-width: $xs) {
    @content;
  }
}

@mixin sm-xx {
  @media (max-width: $sm - 0.2) and (min-width: $xx) {
    @content;
  }
}


/* xs */
@mixin xs-min {
  @media (min-width: $xs) {
    @content;
  }
}

@mixin xs {
  @media (max-width: $xs - 0.2) {
    @content;
  }
}

@mixin xs-xx {
  @media (max-width: $xs - 0.2) and (min-width: $xx) {
    @content;
  }
}


/* xx */
@mixin xx-min {
  @media (min-width: $xx) {
    @content;
  }
}
@mixin xx {
  @media (max-width: $xx - 0.2) {
    @content;
  }
}
