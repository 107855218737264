@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
@import url('/font/stylesheet.css');
$blue: #145099;

@import "mixins-media";

$lr: 1650px;
$lv: 1550px;
$lt: 1440px;
$ul: 1340px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 378px;
$xx: 320px;

.main {
    padding-bottom: 0 !important;
}

.section-title {
  padding: 142px 0 44px 0;

  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;

  font-size: 40px;
  font-style: normal;
}

.title-discription {
    font-family: Lato;
    margin: 0;
    padding-bottom: 36px;
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    line-height: 32px;
}

a:hover {
    text-decoration: none;
}


/*Events  block*/


.event_container {
    width: 100%;
}

.event_container h1 {
    color:#000000;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
}

.event_container .row {
    width: 100%;
    margin: 0;
}

.event-main,
.event-short-hover,
.short-event {
    padding: 0;
    background-color: #effaff;
}

.main_event .carousel {
    width: 620px;

    @include lt {
        width: 510px;

    }
}

.events a {
    color: #000000;
}

.events a:hover {
    text-decoration: none;
    color: #7bd7fe;
}

.event-description a {
    color: rgba(0, 0, 0, 0.5);
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.events .title-discription {
    padding-bottom: 18px;
    font-size: 31px;
    font-style: normal;
    line-height: 32px;
    text-align: left;

    @include lt {
        padding-bottom: 10px;
    }
}

.events .event-description {
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}


.carouselEvents {
    position: relative;
    width: auto;
    margin: 0;
    text-align: left;
}

.carouselEvents p {
    margin: 0;
}


.carouselEventsButtons {
    position: absolute;
    right: 0;
    bottom: 0;
}

.carouselEventsButtons .col {
    padding: 0;
}

.events-button {
    position: static;
    width: auto;
    height: auto;
    padding: 10px 23px 9px 23px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: #effaff;

    @include lt {
        padding: 4.5px 15px 5px 15px;
    }
}

.events-button .carousel-control-next-icon,
.events-button .carousel-control-prev-icon {
    opacity: 0.5;
}

.events-button .carousel-control-next-icon:hover,
.events-button .carousel-control-prev-icon:hover {
    opacity: 1;
}

.events-button:hover {
    border: 1px solid #000000;
}

.article-photo .event-card {
    padding: 26px 8px;
}

.event-card {
    position: absolute;
    padding: 38px 16px 38px 16px;
    text-align: center;
    background-color: #effaff;
}

.event-card .date {
    color: #000000;
    font-family: Lato;
    margin: 0;
    padding-bottom: 26px;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    line-height: 32px;
}

.event-card .month {

    font-family: Lato;
    margin: 0;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    font-size: 22px;
    font-weight: 500;
    font-style: normal;
    line-height: 32px;
}


.event-main {
    padding: 37px 0 38px 25px;

    @include lt {
        padding: 26px 0 26px 24px;
    }
}

.event-main p:first-child {
    margin: 0;
}

.short-event {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-left: 32px;
}

.short-event .article-photo {
    position: relative;
}

.short-event-photo {
    width: 340px;
    height: 400px;

    @include lt {
        width: 280px;
        height: 330px;
    }
}

.main_event_img {
    width: 620px;
    height: 400px;

    @include lt {
        width: 510px;
        height: 330px;
    }
}

.author_description {
    margin-left: 24px;
}

.author_description .auth-prof {
    font-family: Lato;
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;

    @include lt {
        font-size: 16px;
        line-height: 28px;
    }
}

.author_description .auth-name {
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

    @include lt {
        font-size: 16px;
        line-height: 28px;
    }
}

.short-event .event-card {
    bottom: 0;
    width: auto;
    padding: 10px 10px 10px 10px;
}

.short-event .article {
    font-family: Lato;
    font-size: 22px;
    line-height: 32px;
    margin: 0;
}

.short-event-description {
    padding: 38px 0 38px 30px;

    @include lt {
        padding: 26px 0 26px 22px;
    }
}

.short-event-hover {
    position: absolute;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: fit-content;
    height: fit-content;
    padding: 32px 35px 32px 35px;
    transition-delay: 0s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: all;
    opacity: 0;
    background-color: #effaff;
}

.short-event-hover:hover {
    opacity: 1;
}

.event-main .title-discription a {
    font-family: Lato;
}
.short-event-hover .title-discription a {
    color: #000000;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
}
.short-event-hover .title-discription a {
    color: #000000;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;

    @include lt {
        font-size: 26px;
        line-height: 28px;
    }
}

.short-event-hover .article {
    color: #000000;
    padding: 34px 0 34px 0;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

    @include lt {
        padding: 8px 0;
        font-size: 16px;
        line-height: 28px;
        margin: 0;
    }
}

.author_img {
    width: 100px;
    height: 100px;

    @include lt {
        width: 80px;
        height: 80px;
    }
}

.short-event-hover .auth {
    margin: 0;
    padding-bottom: 7px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    line-height: 18px;
}
/*Evets  block*/

/*Industries  block*/

.industries .carousel {
    text-align: left;
}

.industries .section-title {
    color: #000000;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    padding-bottom: 52px;
}

.industries .carousel-indicators {
    width: 100%;
    margin: 0 auto;
    height: 20px;
    bottom: -62px;
}

.industries .title-discription {
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: Lato;
    font-size: 1.375rem;
    line-height: 32px;
    word-break: break-word;
    color: #ffffff;

    @include lt {
        margin-bottom: 15px;
    }
}

    .industries .carousel-indicators li {
        box-sizing: content-box;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: 20px;
        height: 20px;
        margin-left: 15px;
        margin-right: 0px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #cccccc;
        background-clip: padding-box;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: 1;
        transition: opacity .6s ease;
    }

    .industries .carousel-indicators .active {
        background-color: #87f3fe;
    }

    .industries .container {
        min-width: 100%;
    }

    .industry-card {
        padding: 25px 15px 25px 15px;
        color: #ffffff;
    }

.industry-photo {
    max-width: 90%;
    margin-top: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.industries .img-fluid {
    padding: 20px 15px;
}

    .industry-hover-link {
        color: #000000;
    }

    .industry-hover-link:hover {
        text-decoration: none;
        color: #7bd7fe;
    }

.industry {
    margin: 0 25px;
    position: relative;
    top: 0;
    width: 300px;
    height: 300px;
    padding: 0;
    background-color: #130f40;

    @include lt {
        height: 240px;
        width: 240px;
    }

    @include xl {
        margin: 0 12px;
        height: 210px;
        width: 210px;
    }
}

.industry-hover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 25px 15px 25px 15px;
    transition-delay: 0s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: all;
    opacity: 0;
    color: #130f40;
    background-color: #effaff;
}

    .industry-hover .title-discription {
        color: #000000;
    }

    .industry-hover .article {
        margin: 0;
        font-size: 18px;
        line-height: 32px;
    }

    .industry-hover:hover {
        opacity: 1;
    }

    .industries .justify-content-md-center {
        @include md-min {
            justify-content: start !important;
        }
    }
    /*Industries block*/
    /*Our news block*/
    .our-news-main {
        margin-left: 0;
    }

    @media (min-width:1500px) {
        .our-news-main {
            margin-left: 70px;
        }
    }

    .our-news {
        height: 100%;
    }

    .our_news_section {
        width: 80%;

        @include lt {
            width: 80%;
        }

        @include ul {
            width: 90%;
        }
    }

    .our_news_section h1 {
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
    }


.our_news_section .section-title {
    color:#000000;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
}
.our-news .content:hover {
    color: #ffffff;
    background-color: #130f40;
}


    .our-news .content:hover {
        color: #ffffff;
        background-color: #130f40;
    }

    .our-news .article-photo {
        padding: 0;
    }

    .our-news .content {
        padding: 49px 40px 48px 40px;
        background-color: #effaff;
        width: 392px;

        @include ul {
            width: 360px;
        }

        @include xl {
            width: 320px;
        }


        @include md {
            padding: 30px 25px;
        }

        @include sm {
            width: 280px;
        }
    }

    .news_img {
        width: 392px;
        height: 442px;

        @include ul {
            width: 360px;
            height: 400px;
        }


        @include xl {
            width: 320px;
            height: 350px;
        }

        @include sm {
            width: 280px;
            height: 300px;
        }
    }

    .news_box {
        @include lg {
            display: block !important;
            width: fit-content;
            margin: 0 auto;
        }
    }

    .main_date {
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;

        @include ul {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .main_title-discription {
        font-family: Lato;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        color: #000000;

        @include ul {
            font-size: 28px;
            line-height: 28px;
        }

        @include xl {
            font-size: 26px;
            line-height: 28px;
        }
    }

    .main_article {
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }

    .our-news .astin-button {
        margin-top: 0;
    }

    .our-news .title-discription {
        font-family: Lato;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        padding: 0;

        @include ul {
            font-size: 28px;
            line-height: 28px;
        }

        @include xl {
            font-size: 26px;
            line-height: 28px;
        }
    }

    .our-news .date {
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        padding: 16px 0;
        margin: 0;
        color: #000000;

        @include ul {
            font-size: 16px;
            line-height: 28px;
            padding: 14px 0;
        }

        @include xl {
            padding: 10px 0;
        }
    }

    .main_news {
        background-color: #effaff;

        @include md {
            display: block !important;
        }
    }

    .short-news {
        width: 20vw;

        @include ul {
            width: 23vw;
        }

        @include lg {
            width: 100%;
            margin-top: 40px;
        }
    }

    .short-new {
        margin-bottom: 39px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @include ul {
            margin-bottom: 37px;
        }

        @include xl {
            margin-bottom: 28px;
        }
    }

    .our-news .content:hover .main_title-discription {
        color: #ffffff;
    }

    .our-news .content:hover .main_article {
        color: #ffffff;
    }

    .our-news .content:hover .main_date {
        color: #ffffff;
    }
    /*Our news block*/
    /*Invest block*/
    .investment {
        overflow-x: hidden;
    }

    .investment .col-md-6,
    .investment .col-md-4 {
        margin: 0;
        padding: 0;
    }

    .investment .container {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .investment .section-title {
        color: black;
        text-transform: uppercase;
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 52px;
    }

    .investment .img-fluid {
        height: 440px;
        width: 100%;
        height: 100%;
    }

    .investment .invest-count {
        text-align: center;
        white-space: nowrap;
        text-transform: uppercase;
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0em;

        @include ul {
            font-size: 2.5rem;
        }
    }

    .investment .title-discription {
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        color: #ffffff;
    }

    .investment .text__description {
        margin-top: 36px;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
    }

    .invest-box-main .row {
        width: 100%
    }

    .investment .invest-box-main {
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        color: #ffffff;
        background-color: #130f40;
    }

    .investment .col-md-4 {
        padding: 50px 20px 50px 20px;
        word-wrap: break-word;
    }

    .investment .invest-box {
        min-height: 360px;
        padding: 25px 47px 75px;
        color: #130f40;
    }

    .investment .row {
        margin-right: 0;
        margin-left: 0;
    }

    .investment .invest-box .title-discription {
        word-break: break-word;
        text-align: center;
        color: #130F40;
    }

    .investment .text-description {
        word-break: break-word;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    .invest-box:hover {
        color: #ffffff;
        background-color: #130f40;
    }

    .invest-box:hover .text-description {
        color: #ffffff;
    }

    .invest-box:hover .title-discription {
        color: #ffffff;
    }

    .invest-box .text-description {
        color: #130F40;
    }

    .first {
        background-color: #7bd7fe;
    }

    .second {
        background-color: #9fe3ff;
    }

    .third {
        background-color: #c1edff;
    }

    .fourth {
        background-color: #d8f3ff;
    }
    /*Invest block*/
    /*Career block*/
    .career {
        padding-bottom: 140px;

        @include md {
            padding-left: 0;
        }
    }

    .career .section-title {
        color: black;
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 40px;
        text-align: center;
    }

    .wrapper__all__content {
        padding-left: 15px;

        @include xl {
            margin: 0 auto;
        }
    }

    .career .content {
        padding-right: 50px;
        margin-bottom: 65px;

        @include xl {
            padding-left: 0;
        }
    }

    .career .article {
        color: black;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        margin-bottom: 14px;
    }

    .career .title-discription {
        color: black;
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        padding-bottom: 42px;
    }

    .career .article-photo {
        width: 100%;
        height: 100vh;
        max-height: 375px;

        @include xl {
            padding-left: 0;
        }

        @include md {
            width: 100%;
            max-height: 400px;
        }
    }

    .career .img-fluid {
        width: 100%;
        max-height: 375px;
    }

    .astin-button {
        text-transform: uppercase;
        text-align: center;
        border: 1px solid #6db5ff;
        color: #6db5ff;
        background-color: #fff;
        padding: 5px 9px;
        width: 129px;
        height: 34px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 45px;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: left;
        outline: none !important;
    }

    .astin-button:hover {
        background-color: #6db5ff;
        color: #ffffff;
    }

    .button-row {
        margin: 0;
    }

    .wrapper__space {
        @include xl {
            display: none;
        }
    }
    /*Career block*/
    /*Header*/
    .navbar {
        box-shadow: 0 0 15px rgba(0,0,0,0.1);
    }

    .header {
        background-color: #fff;
        position: fixed;
        width: 100%;
        z-index: 999;
    }

    .nav {
        width: 100%;
    }

    .nav .navbar-toggler {
        outline: none !important;
    }

    .bg-light {
        background-color: #fff !important;
        border-bottom: 1px solid #dee2e6 !important;
    }

    .navbar-collapse {
        flex-grow: unset;
    }

    .search_box {
        margin-left: 35px;
    }

    .search_box:hover {
        padding-bottom: 2px;
        border-bottom: 2px solid #000000;

        .search_input input {
            visibility: visible;
        }
    }

    .search_input input {
        visibility: hidden;
        border: none;
        outline: none;
        margin-left: 15px;
    }

    .info_panel ul {
        margin: 0;

        @include lg-xs {
            align-items: baseline !important;
            padding-left: 10px
        }
    }


.header__slider {
    height: 84vh;
    width: 100%;
    position: relative;
}
.header__slider .carousel-inner p {
   margin-bottom: 36px;
}
.logo_text {
    position: absolute;
}
.header__links {
    position: relative;
    display: flex;
    justify-content: space-around;
}
.header__links ul{
    z-index: 200;
}
.links__list {
    list-style: none;
    padding-left: 0;
    flex-direction: column;
    margin-bottom: 0;
    line-height: 1;
}
    .info_panel li {
        list-style-type: none;
        padding: 8px 25px;
    }

    .info_panel li:last-child {
        list-style-type: none;
        padding: 5px 25px;
        padding-right: 6px;
    }


    .btn_contacts {
        border: 2px solid #42b7e8;
        outline: none !important;
        background-color: #fff;
        color: #42b7e8;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;

        &:hover {
            background-color: #6db5ff;
            color: #ffffff;
        }
    }
    /*Header*/
    .header_row {
        width: 100%;
        margin: 0;
    }

    .header_col {
        padding: 0;
    }
    /*HeaderSlider*/


    .header__links {
        position: relative;
        display: flex;
        justify-content: space-around;
    }

    .header__links ul {
        z-index: 200;
    }

    .links__list {
        list-style: none;
        padding-left: 0;
        flex-direction: column;
        margin-bottom: 0;
        line-height: 1;

        .links__item {
            margin-bottom: 30px;
            font-size: 16px;
            color: #fff;
            text-transform: uppercase;
            line-height: 20px;

            &:hover {
                color: #ccc;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .links__link {
            cursor: pointer;
            color: #000000;

            &:hover {
                color: $blue;
                text-decoration: none;
            }
        }
    }

    .header__links {
        font-family: 'Lato', sans-serif;
        margin-bottom: 65px;
        justify-content: space-evenly;

        .links__item {
            color: #000000 !important;

            a {
                &:hover {
                    color: $blue;
                    text-decoration: none;
                }
            }
        }
    }

    .links__list {
        list-style: none;
        padding-left: 0;
        flex-direction: column;
        margin-bottom: 0;
        line-height: 1;

        .links__item {
            margin-bottom: 30px;
            font-size: 16px;
            color: #fff;
            text-transform: uppercase;
            line-height: 20px;

            &:hover {
                color: #ccc;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .links__link {
            cursor: pointer;
            color: #000000;

            &:hover {
                color: $blue;
                text-decoration: none;
            }
        }
    }

    .header__links {
        font-family: 'Lato', sans-serif;
        margin-bottom: 65px;
        justify-content: space-evenly;

        .links__item {
            color: #000000 !important;

            a {
                &:hover {
                    color: $blue;
                    text-decoration: none;
                }
            }
        }
    }

    .btn__box {
        display: flex;
        margin-bottom: 62px;
        justify-content: center;

        .btn-next {
            text-transform: uppercase;
            text-align: center;
            border: 1px solid #6db5ff;
            color: #6db5ff;
            padding: 3px 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 1px;
            text-align: left;


            &:hover {
                background-color: #6db5ff;
                color: #ffffff;
            }
        }
    }

    .carousel {
        width: 55%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 60px
    }

    .carousel-inner p {
        margin-bottom: 15px
    }

    .carousel-inner p:last-child {
        margin-bottom: 0
    }

.slider__name {
    color: #000000;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
}

.slider__price {
    color: #000000;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
}

.slider__des {
    color: #000000;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

    .carousel-control-prev, .carousel-control-next {
        opacity: 1
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        background-image: url("../img/icons/arrow_slider.svg");
        width: 32px;
        height: 58px;

        @include lt {
            width: 26px;
            height: 52px;
        }
    }

    .carousel-control-next-icon {
        transform: rotate(180deg)
    }
    /*HeaderSlider*/
    /*Footer*/
    .footer {
        position: absolute;


        @include md-xs {
            display: flex;
            justify-content: center;
        }
    }

    .header_title {
        padding-top: 100px;

        @include md-xs {
            padding: 0;
        }
    }

    .header_title ul {
        float: right;
        margin: 0;
        padding-bottom: 36px;

        @include md-xs {
            display: flex;
            justify-content: center;
            padding: 0;
            padding: 36px 0;
        }
    }

    .header_title li {
        list-style-type: none;
        padding: 0px 19px;
        z-index: 10;
    }

    .header_title li:last-child {
        list-style-type: none;
        padding: 0px 19px;
        padding-right: 22px;
    }


.logo_text{
    position:absolute;
    bottom:45px;
}

    .header_title a {
        text-decoration: none;
        color: #000;
        text-transform: uppercase;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: left;
    }



    .header_title a:hover {
        color: #6aafff;
        text-decoration: none;
        border-bottom: 1px solid #6aafff;
        padding-bottom: 4px;
    }

    .logo {
        position: relative;
        padding-top: 78px;
        padding-left: 50px;

        @include md-xs {
            padding-left: 0px;
        }
    }
    /*Footer*/
    .footer {
        position: absolute;
        flex: 0 0 auto;
        width: 100%;
        padding-top: 55px;
        padding-bottom: 10px;
        white-space: nowrap;
        background-color: #130f40;
        font-family: "Lato", sans-serif;
        line-height: 60px;



        @media (min-width: 1200px) {
            .container {
                max-width: 1200px;
            }
        }
    }


    .footer__image {
        height: 45px;
        width: 45px;
    }

.footer__text {
    position:relative;
    bottom: -5px;
    font-family: Magistral;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 71px;
    letter-spacing: 0em;
    color: #fff;
    margin-left: 16px;
}

    .content-class.container,
    footer > .container {
        position: relative;
    }

    .footer__astin-title {
        margin-bottom: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer__links {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }

    .footer__links .row {
        width: 100%;
        margin: 0;
    }

    .footer__links .col-lg {
        padding: 0;
    }

    .footer__links .col-6 {


        @include md {
            justify-content: normal !important;
        }
    }

    .footer__links .col_1 {


        @include md {
            padding-left: 5vw;
        }
    }

    .footer__links .col_2 {


        @include md {
            justify-content: flex-end !important;
            padding-right: 5vw;
        }
    }

    .footer__links .col_3 {
        order: 1;

        @include md {
            padding-top: 40px;
            order: 3;
        }
    }

    .footer__links .col_4 {
        order: 2;

        @include lg {
            padding-top: 40px;
        }

        @include md {
            order: 1;
            padding-left: 5vw;
        }
    }

    .footer__links .col_5 {
        order: 3;

        @include lg {
            padding-top: 40px;
        }

        @include md {
            justify-content: flex-end !important;
            order: 2;
            padding-right: 5vw;
        }
    }

    .footer__links .col_2 .links__item {
        @include md {
            text-align: end
        }
    }

    .footer__links .col_5 .links__item {


        @include md {
            text-align: end
        }
    }




    .links__list {
        flex-direction: column;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        line-height: 1;

        .links__item {
            margin-bottom: 30px;
            text-transform: uppercase;
            color: #ffffff;
            font-size: 16px;
            line-height: 20px;

            &:hover {
                color: #cccccc;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .links__link {
            cursor: pointer;
            color: #000000;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 1px;


            &:hover {
                text-decoration: none;
                color: $blue;
            }
        }
    }

    .footer__links .links__list .links__link {
        color: #fff;
    }

    .socials {
        margin-top: 40px;
    }

    .socials__list {
        justify-content: center;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        li {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            cursor: pointer;
        }
    }

    .copyright {
        text-align: center;

        span {
            color: #ffffff;
            font-size: 14px;
        }
    }
    /*Footer*/
    /*Cube*/
    .cube__decoration {
        position: absolute;
        width: 299px;
        height: 300px
    }

    .cube__decoration_left {
        left: 0;
        bottom: 0
    }

    .cube__decoration_right {
        top: 0;
        right: 0
    }

    .square1 {
        position: absolute;
        width: 217px;
        height: 217px;
        background: linear-gradient(180deg, rgba(123,215,254,0.6) 0%, rgba(255,255,255,0) 100%),rgba(135,243,254,0.2)
    }

    .square1_left {
        left: 0px;
        bottom: 0;
        transform: matrix(1, 0, 0, -1, 0, 0)
    }

    .square1_right {
        right: 0;
        top: 0
    }

    .square2 {
        position: absolute;
        width: 147px;
        height: 147px;
        background: linear-gradient(180deg, rgba(123,215,254,0.2) 0%, rgba(255,255,255,0) 100%),rgba(135,243,254,0.1)
    }

    .square2_left {
        left: 37%;
        top: 11%;
        transform: matrix(1, 0, 0, -1, 0, 0)
    }

    .square2_right {
        right: 40%;
        top: 40%
    }

    .square3 {
        position: absolute;
        width: 118px;
        height: 118px;
        background: linear-gradient(180deg, rgba(123,215,254,0.2) 0%, rgba(255,255,255,0) 100%),rgba(135,243,254,0.05)
    }

    .square3_left {
        left: 62%;
        top: -2%;
        transform: matrix(1, 0, 0, -1, 0, 0)
    }

    .square3_right {
        top: 60%;
        right: 60%
    }


    .cube__decoration_left.cube__decoration_header {
        bottom: 0;
    }
    /*Cube*/
    /*Cookies*/
    #cookieAcceptModal {
        padding-right: 0px !important
    }

    #cookieAcceptModal .modal-dialog {
        margin: 0;
        min-width: 100%;
        bottom: 0;
        position: absolute
    }

    #cookieAcceptModal .container {
        padding: 2rem 0rem
    }

    #cookieAcceptModal .modal-content {
        border-radius: 0rem;
        border: 0
    }

    #cookieAcceptModal .cookie-text {
        font-size: 18px;
        font-family: Roboto;
        font-weight: 400
    }

    #cookieAcceptModal a {
        color: black;
        text-decoration: underline
    }

    #cookieAcceptModal a:hover {
        text-decoration: none;
        cursor: pointer;
        color: #6DB5FF
    }

    #cookieAcceptModal a:active {
        color: black
    }

    #cookieAcceptModal a:visited {
        color: black
    }

    #cookieAcceptModal #cookieAcceptBtn {
        padding: 6px 12px;
        margin-left: 20px;
        font-size: 16px
    }


    .navbar__sign {
        padding: 8px 14px;
        color: #42b7e8;
        vertical-align: middle;
        border: 2px solid #42b7e8;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .navbar__sign:hover {
        color: #fff;
        background-color: #42b7e8
    }

    header {
        box-shadow: 0 0 15px rgba(0,0,0,0.1);
    }
    /*Cookies*/
    
